/* eslint-disable react/jsx-no-target-blank*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, FormSection } from '@nike/epic-react-ui'

import './HomePage.css'

const AccessToGithub = () => (
  <div className='home-page__container'>
    <ol>
      <li>
        Login to{' '}
        <a href='https://github.nike.com' target='_blank'>
          https://github.nike.com
        </a>
      </li>
      <li>
        Navigate to{' '}
        <a href='https://baat.niketech.com' target='_blank'>
          https://baat.niketech.com
        </a>{' '}
        , if the dashboard there says you do not have access to Github, press the &#34;Grant
        Access&#34; button.
      </li>
      <li>
        Close your browser and then login to{' '}
        <a href='https://github.nike.com' target='_blank'>
          https://github.nike.com
        </a>{' '}
        again.
      </li>
    </ol>
  </div>
)

const CallystoDocs = () => (
  <div>
    <a href='https://pages.github.nike.com/hyperflow/callysto-docs/' target='_blank'>
      <Button className='center-btn'>View Callysto Docs</Button>
    </a>
  </div>
)

const LauchCallysto = ({ launchCallysto }) => (
  <Button className='center-btn' onClick={launchCallysto}>
    Launch Notebook
  </Button>
)

export class HomePage extends Component {
  launchCallysto = user => {
    // console.log('TODO: send info to New Relic!!!', user)
    // NRQL > SELECT * FROM PageAction WHERE actionName='USER_LAUNCHED_CALLYSTO'
    // NRQL > SELECT email, fullname, timestamp, userAgentName, userAgentOS FROM PageAction WHERE actionName='USER_LAUNCHED_CALLYSTO' WHERE fullname IS NOT NULL
    newrelic.addPageAction('USER_LAUNCHED_CALLYSTO', user) // eslint-disable-line

    // Launch the notebook
    window.location.href = 'https://kubeflow.callysto.hyperflow-test.nikecloud.com/'
  }

  render() {
    const { name, email } = this.props.user
    return (
      <div className='container'>
        <FormSection title='Instructions for getting access to Github:'>
          <AccessToGithub />
        </FormSection>

        <FormSection title='View Callysto Documentation:'>
          <CallystoDocs />
        </FormSection>

        <FormSection title='View Callysto Notebook:'>
          <LauchCallysto launchCallysto={() => this.launchCallysto({ fullname: name, email })} />
        </FormSection>
      </div>
    )
  }
}

export default connect(
  state => ({ user: state.auth }),
  null
)(HomePage)
