import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { useRoutes, Link } from 'raviger'
import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  loginUser,
  logoutUser
} from "@nike/oauth-client";
import { Button, NavBar } from '@nike/epic-react-ui'

import './App.css'
import config from "../config"
import routes from './routes.js'
import { LoginRequired } from '../auth'
import Error from '../components/Error.js'
import NotFoundPage from './NotFoundPage.js'
import Spinner from '../components/Spinner.js'
import OfflineBanner from '../offline/OfflineBanner.js'

const navBarLinks = []

const App = ({ initializeUserAuth, loginError, loginFinished, loginUser, logoutUser }) => {
  useEffect(() => initializeUserAuth())

  const routeResult = useRoutes(routes) || <NotFoundPage />

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  if (!loginFinished) {
    return <Spinner large />
  }

  return (
    <div>
      <OfflineBanner />
      <div>
        <NavBar
          name={config.appFullName}
          logo='/logo.png'
          rootPath='/'
          routes={navBarLinks}
          RouterLink={Link}
          slackURL={config.slackURL}
        />
        <LoginRequired>
            <div style={{ margin: '1rem' }}>{routeResult}</div>
        </LoginRequired>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state)
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App)
