import React from 'react'
import { 
  loginUser, 
  selectIsLoggedIn, 
  hasAuthCheckFinished 
} from '@nike/oauth-client'
import { connect } from 'react-redux'
import { Button } from "@nike/epic-react-ui"

import Spinner from '../components/Spinner.js'

const mapProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  loginFinished: hasAuthCheckFinished(state)
})

const LoginRequired = connect(
  mapProps,
  { loginUser }
)(({ isLoggedIn, loginFinished, title, loginUser, children }) => {
  if (!loginFinished) {
    return <Spinner title={title} noHeader />
  }

  if (!isLoggedIn) {
    return (
      <div>
        <h1>You must be logged in to view this page</h1>
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  return React.Children.only(children)
})

// eslint-disable-next-line react/display-name
const requireLogin = title => WrappedComponent => props => (
  <LoginRequired title={title}>
    <WrappedComponent {...props} />
  </LoginRequired>
)

export { LoginRequired }
export default requireLogin
