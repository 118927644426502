import merge from 'deepmerge'

const base = {
  appName: 'callysto-ingress-site',
  appFullName: 'Callysto Preview',
  oAuth: {
    client_id: 'nike.niketech.callysto-ingress-site',
    response_type: 'id_token token',
    scope: 'profile openid email'
  }
}

const dev = merge(base, {
  slackURL: 'slack://channel?team=T0XT0BQ3V&id=CNASTK8N6',
  apiUrl: '/dev',
  oAuth: {
    redirect_uri: window.location.origin,
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize'
  }
})

const prod = merge(base, {
  slackURL: 'slack://channel?team=T0XT0BQ3V&id=CNASTK8N6',
  apiUrl: '/prod',
  oAuth: {
    redirect_uri: 'https://preview.callysto.nikecloud.com',
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize'
  }
})

const config = window.location.origin.includes('//preview.callysto.nikecloud.com') ? prod : dev

export default config
